import { createSelector } from '@reduxjs/toolkit';

import { initialState } from 'modules/common/auth/slice';
import { capitalizeFirst } from 'modules/common/helpers/strings';

const selectDomain = (state) => state['feature/base-auth'] || initialState;
//
export const selectAuthUser = createSelector([selectDomain], (authState) => authState.user);
//
export const selectTokens = createSelector([selectDomain], (authState) => authState.tokens);
//
export const selectRefreshToken = createSelector(
  [selectDomain],
  (authState) => authState.tokens?.refreshToken
);
//
export const selectAccessToken = createSelector(
  [selectDomain],
  (authState) => authState.tokens?.accessToken
);
//
export const selectResetPasswordToken = createSelector(
  [selectDomain],
  (authState) => authState?.resetPasswordToken
);
//
export const selectUserFullName = createSelector(
  [selectDomain],
  (authState) =>
    `${capitalizeFirst(authState?.user?.firstName)} ${capitalizeFirst(authState?.user?.lastName)}`
);
//
export const selectOrganizationId = createSelector(
  [selectDomain],
  (authState) => authState?.user?.organizationId
);
//
export const selectAccessTokenExpired = createSelector(
  [selectDomain],
  (authState) => authState.tokens?.expiresIn
);
/**
 * Obtain id token from auth slice
 */
export const selectIdToken = createSelector(
  [selectDomain],
  (authState) => authState.tokens?.idToken
);
/**
 * Getting is token revoked state for dashboard component
 */
export const selectTokenRevocation = createSelector(
  [selectDomain],
  (state) => state.isTokenRevoked
);

/**
 * Here mentioned users feature request structure
 */
const API = {
  GET_USER_LIST: {
    path: '/organizations/:organizationId/users?:query',
    method: 'GET',
  },
  GET_SELECTED_USER_LIST: {
    path: '/organizations/:organizationId/users?:query',
    method: 'GET',
  },
  GET_ROLES: {
    path: '/organizations/:organizationId/roles',
    method: 'GET',
  },
  GET_HOTELS: {
    path: '/organizations/:organizationId/users/:userId/hotels?:query',
    method: 'GET',
  },
  GET_ALL_HOTELS: {
    path: '/organizations/:organizationId/hotels?:query',
    method: 'GET',
  },
  POST_USERS: {
    path: '/organizations/:organizationId/users',
    method: 'POST',
  },
  POST_USERS_ADMIN: {
    path: '/organizations/:organizationId/users/admin',
    method: 'POST',
  },
  POST_USERS_GENERAL: {
    path: '/organizations/:organizationId/users/general',
    method: 'POST',
  },
  PATCH_USER: {
    path: '/organizations/:organizationId/users/:userId',
    method: 'PATCH',
  },
  PATCH_ROLE: {
    path: '/organizations/:organizationId/users/:userId/assign-roles',
    method: 'PATCH',
  },
  PATCH_HOTEL: {
    path: '/organizations/:organizationId/users/:userId/hotels',
    method: 'PATCH',
  },
  DELETE_USER: {
    path: '/organizations/:organizationId/users/:userId',
    method: 'DELETE',
  },
  INVITE_USER: {
    path: '/organizations/:organizationId/invites',
    method: 'POST',
  },
  GET_USER_INVITES: {
    path: '/organizations/:organizationId/invites?:query',
    method: 'GET',
  },
  POST_CHANGE_PASSWORD: {
    path: '/organizations/:organizationId/users/:userId/change-password',
    method: 'POST',
  },
};
//
export default API;

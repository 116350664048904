/* eslint-disable  no-unused-expressions */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Autocomplete,
  Checkbox,
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotification } from 'modules/common/notifications/selectors';
import { selectOrganizationId, selectAuthUser } from 'modules/common/auth/selectors';
import {
  selectLoggedUserHotelsList,
  selectRoleList,
  selectHotelsSelectionList,
} from 'modules/users/selectors';
import ERROR_TYPES from 'modules/common/constants/error-types';
import { SUPER_ADMIN_ROLE, GUEST_ROLE } from 'modules/common/constants/roles';
import { usersActions } from 'modules/users/slice';
import { notificationActions } from 'modules/common/notifications/slice';
import { isEmpty } from 'modules/common/helpers/object';
import { capitalizeFirst } from 'modules/common/helpers/strings';
import { useEffect, useState } from 'react';
import { inviteUserValidation } from 'modules/users/validation/invite-user-form-validation';
import { Alert, TextField } from './style';
/**
 * Modal implementation to send user invitations
 * @returns
 */
const InviteUserFormDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const notification = useSelector(selectNotification);
  const currentUser = useSelector(selectAuthUser);
  const hotelList = useSelector(selectLoggedUserHotelsList);
  const allHotelList = useSelector(selectHotelsSelectionList);
  const RoleList = useSelector(selectRoleList);
  //
  const [selectedOptions, setSelectedOptions] = useState([]);
  //
  const onSubmitInviteUser = async (values) => {
    const hotels = values?.hotels?.map((item) => item?.id);
    const valueObj = {
      email: values?.email,
      role: values?.role,
      hotels,
      sentBy: currentUser?.id,
    };
    dispatch(usersActions.inviteUser({ ...valueObj, organizationId }));
  };
  //
  const initialValues = {
    email: '',
    role: RoleList?.[2]?.name,
    hotels: [],
  };
  //
  const allSelected = !isEmpty(hotelList)
    ? hotelList?.length === selectedOptions?.length
    : allHotelList?.length === selectedOptions?.length;
  const handleToggleOption = (selectedOps) => {
    setSelectedOptions(selectedOps);
  };
  const handleClearOptions = () => setSelectedOptions([]);
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      !isEmpty(hotelList) ? setSelectedOptions(hotelList) : setSelectedOptions(allHotelList);
    } else {
      handleClearOptions();
    }
  };
  const handleToggleSelectAll = () => handleSelectAll && handleSelectAll(!allSelected);
  const filter = createFilterOptions();
  //
  const modalAction = (action) => {
    if (notification?.isEnabled) dispatch(notificationActions.resetNotification());
    onClose(action);
    handleClearOptions();
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      modalAction(false);
    }
  }, [notification]);
  //
  return (
    <Dialog
      open={open}
      onClose={() => modalAction(false)}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Invite User</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={inviteUserValidation}
          onSubmit={onSubmitInviteUser}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert mt={2} mb={3} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <TextField
                type="email"
                name="email"
                size="small"
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
                label="Email Address"
              />
              <TextField
                select
                size="small"
                name="role"
                value={values.role}
                label="Role"
                onChange={(event) => {
                  setFieldValue('role', event.target.value);
                }}
                error={Boolean(touched.role && errors.role)}
                helperText={touched.role && errors.role}
                fullWidth
                sx={{ marginY: 2 }}
                InputLabelProps={{ shrink: true }}
              >
                {!isEmpty(RoleList) &&
                  RoleList?.filter(
                    (userRole) =>
                      userRole?.name !== SUPER_ADMIN_ROLE && userRole?.name !== GUEST_ROLE
                  ).map((type) => (
                    <MenuItem key={type?.id} value={type?.name}>
                      {capitalizeFirst(type?.name.replace(/-/g, ' '))}
                    </MenuItem>
                  ))}
              </TextField>
              <Autocomplete
                multiple
                fullWidth
                size="small"
                disableClearable
                disableCloseOnSelect
                name="hotels"
                limitTags={2}
                componentsProps={{
                  paper: {
                    sx: {
                      width: '90%',
                      marginLeft: '10%',
                    },
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    padding: 0,
                    height: 'fit-content',
                  },
                  marginY: 2,
                }}
                onBlur={handleBlur}
                options={isEmpty(hotelList) ? allHotelList : hotelList}
                value={selectedOptions}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  return [{ id: 0, label: 'Select All' }, ...filtered];
                }}
                renderOption={(props, option, { selected }) => {
                  const selectAllProps =
                    option.label === 'Select All' ? { checked: allSelected } : {};
                  return (
                    <li {...props}>
                      <Checkbox checked={selected} {...selectAllProps} />
                      {option.label}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    error={Boolean(touched.hotels && errors.hotels)}
                    helperText={touched.hotels && errors.hotels}
                    fullWidth
                    {...params}
                    label="Hotels"
                  />
                )}
                onChange={(event, selectedOps, reason) => {
                  if (reason === 'selectOption' || reason === 'removeOption') {
                    if (selectedOps.find((option) => option.label === 'Select All')) {
                      handleToggleSelectAll();
                      !isEmpty(hotelList)
                        ? setFieldValue('hotels', hotelList)
                        : setFieldValue('hotels', allHotelList);
                    } else {
                      handleToggleOption && handleToggleOption(selectedOps);
                      setFieldValue('hotels', selectedOps);
                    }
                  } else if (reason === 'clear') {
                    handleClearOptions && handleClearOptions();
                    setFieldValue('hotels', []);
                  }
                }}
              />
              <DialogActions>
                <Button onClick={() => modalAction(false)} variant="contained" color="primary">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="success" disabled={isSubmitting}>
                  Invite User
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
//
export default InviteUserFormDialog;

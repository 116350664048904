import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Autocomplete,
} from '@mui/material';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotification } from 'modules/common/notifications/selectors';
import { selectOrganizationId, selectAuthUser } from 'modules/common/auth/selectors';
import { selectUpdatedUser, selectHotelsSelectionList } from 'modules/users/selectors';
import ERROR_TYPES from 'modules/common/constants/error-types';
import ROLE_NAMES from 'modules/common/constants/user-roles';
import {
  DEMO_ROLE,
  PREFERRED_ROLE,
  SUPER_ADMIN_ROLE,
  USER_ROLE,
} from 'modules/common/constants/roles';
import { notificationActions } from 'modules/common/notifications/slice';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { isEmpty } from 'modules/common/helpers/object';
import TOAST_TYPES from 'modules/common/constants/toast-types';
import { addUserValidation } from '../../validation/add-user-form-validation';
import { Alert, TextField } from './style';
import { usersActions } from '../../slice';

const AddUserFormDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const notification = useSelector(selectNotification);
  const currentUser = useSelector(selectAuthUser);
  const hotelList = useSelector(selectHotelsSelectionList);
  const updatedUser = useSelector(selectUpdatedUser);
  //
  const [role, setRole] = useState(ROLE_NAMES[0].label);
  const [userHotels, setUserHotels] = useState([]);
  //
  const modalAction = (action) => {
    if (notification?.isEnabled) dispatch(notificationActions.resetNotification());
    onClose(action);
  };
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
      modalAction(false);
    }
  }, [notification]);
  //
  useEffect(() => {
    if (!isEmpty(updatedUser)) {
      const hotelIds = userHotels?.map((selectedHotel) => selectedHotel.id);
      dispatch(
        usersActions.updateHotelsList({
          hotels: hotelIds,
          organizationId,
          userId: updatedUser?.id,
        })
      );
    }
  }, [updatedUser]);
  //
  const onSubmitAddUser = async (values) => {
    setUserHotels([...values.hotels]);
    const valueObj = {
      email: values.email,
      username: values.username,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
    };
    if (role === ROLE_NAMES[0].label) {
      dispatch(usersActions.createGeneralUsers({ ...valueObj, organizationId }));
    } else if (role === ROLE_NAMES[3].label || role === ROLE_NAMES[4].label) {
      dispatch(
        usersActions.createUsers({
          ...valueObj,
          roleName: role === ROLE_NAMES[3].label ? DEMO_ROLE : PREFERRED_ROLE,
          organizationId,
        })
      );
    } else {
      dispatch(usersActions.createAdminUsers({ ...valueObj, organizationId }));
    }
  };
  //
  const initialValues = {
    email: '',
    username: '',
    firstName: '',
    lastName: '',
    password: '',
    hotels: [],
  };
  //
  return (
    <Dialog open={open} onClose={() => modalAction(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add User</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={addUserValidation}
          onSubmit={onSubmitAddUser}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert mt={2} mb={3} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
              <TextField
                size="small"
                type="email"
                name="email"
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
                label="Email Address"
              />
              <TextField
                size="small"
                name="username"
                value={values.username}
                error={Boolean(touched.username && errors.username)}
                helperText={touched.username && errors.username}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
                label="Username"
              />
              <TextField
                size="small"
                name="firstName"
                value={values.firstName}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
                label="First Name"
              />
              <TextField
                size="small"
                name="lastName"
                value={values.lastName}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
                onBlur={handleBlur}
                onChange={handleChange}
                fullWidth
                label="Last Name"
                my={2}
              />
              <TextField
                size="small"
                type="password"
                name="password"
                value={values.password}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                fullWidth
                label="Password"
                my={2}
              />
              <TextField
                size="small"
                select
                name="role"
                disabled={
                  currentUser.roles[0].name === USER_ROLE ||
                  currentUser.roles[0].name === DEMO_ROLE ||
                  currentUser.roles[0].name === PREFERRED_ROLE
                }
                value={role}
                label="Role"
                onChange={(event) => setRole(event.target.value)}
                fullWidth
                sx={{ marginY: 2 }}
                InputLabelProps={{ shrink: true }}
              >
                {ROLE_NAMES?.filter((userRole) => userRole.key !== SUPER_ADMIN_ROLE).map((type) => (
                  <MenuItem key={type.key} value={type.label}>
                    {type.label}
                  </MenuItem>
                ))}
              </TextField>
              <Autocomplete
                multiple
                disableClearable
                name="hotels"
                size="small"
                limitTags={2}
                componentsProps={{
                  paper: {
                    sx: {
                      width: '90%',
                      marginLeft: '10%',
                    },
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    padding: 0,
                    height: 'fit-content',
                  },
                  marginY: 2,
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={hotelList || []}
                filterSelectedOptions
                onChange={(e, value) => setFieldValue('hotels', value)}
                onBlur={handleBlur}
                renderInput={(params) => <TextField label="Hotels" size="small" {...params} />}
              />
              <DialogActions>
                <Button onClick={() => modalAction(false)} variant="contained" color="primary">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="success" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
//
export default AddUserFormDialog;

/**
 * Visual filter feature related request structures are defined here
 */
const API = {
  GET_VISUAL_FILTERS: {
    path: '/organizations/:organizationId/visual-filters?:query',
    method: 'GET',
  },
  POST_VISUAL_FILTER: {
    path: '/organizations/:organizationId/visual-filters',
    method: 'POST',
  },
  DELETE_VISUAL_FILTER: {
    path: '/organizations/:organizationId/visual-filters/:id',
    method: 'DELETE',
  },
  PATCH_VISUAL_FILTER: {
    path: '/organizations/:organizationId/visual-filters/:visualFilterId',
    method: 'PATCH',
  },
  GET_VISUAL_FILTER_BY_ID: {
    path: '/organizations/:organizationId/visual-filters/:selectedFilterId',
    method: 'GET',
  },
};
//
export default API;
/**
 * Tags used for visual filter categorization are defined here
 */
export const TAG_LIST = [
  'HOTEL_NAME',
  'SEGMENT_BREAKDOWN',
  'PMS_SYNC',
  'PACE_HORIZON',
  'DATE_COMPARISON',
  'PACE_VARIABLE',
  'SUMMARY_PACE_VARIABLE',
  'CHAIN_NAME',
  'TARGET_DATE',
  'BOOKING_DATE',
  'REVENUE_FORECAST',
  'ROOM_FORECAST',
  'PMS_SYNC_FORECAST',
  'PACE_FORECAST',
  'REGION',
  'TEST',
  'DETAILED',
  'CUSTOM_DATE_COMPARISON',
  'CUSTOM',
  'SEGMENT_GROUP_1',
  'SEGMENT_GROUP_2',
  'NORMALIZED',
  'AGGREGATION',
  'WEB_CLICK',
  'SCALING_FACTOR',
  'CLICK_STEPS',
  'WEB_CLICK_CHANNEL',
  'SUMMARY',
  'HOTELNAME_ORDER',
  'SUMMARY_SEGMENT_BREAKDOWN',
  'PACE_WIDGET',
  'PERCENTAGE',
  'UTM_FOCUS_ON',
  'IS_FORECAST',
  'HOTEL_GROUP_1',
  'I_PREFER_SEGMENT_BREAKDOWN',
  'METRIC_SELECTION',
  'UTM_BREAKDOWN',
  'UTM_FOCUS_ON',
  'IS_FORECAST',
  'HOTEL_GROUP_1',
  'HOTEL_GROUP_ORDER',
  'HOTEL_ID',
  'FAR_FIELD_PMS_SYNC',
  'GRANULARITY',
  'SEGMENT_WISE_BREAKDOWN'
];
/**
 * Types used for visual filter categorization are defined here
 */
export const TYPE_LIST = [
  'NAME',
  'ALL',
  'DATE',
  'Region',
  'UNALIGNED',
  'ALIGNED',
  'Channel',
  'Market Segment',
  'Rate Category',
  'Market Segment (raw)',
];

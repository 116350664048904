import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessTokenExpired, selectRefreshToken } from '../auth/selectors';
import { authActions } from '../auth/slice';
import TIME_OUTS from '../constants/time-outs';

const useUpdateToken = () => {
  const dispatch = useDispatch();
  //
  const selectExpiredTime = useSelector(selectAccessTokenExpired);
  const refreshToken = useSelector(selectRefreshToken);
  //
  const tryUpdateToken = useCallback(async () => {
    dispatch(authActions.refreshToken({ refreshToken }));
  }, [refreshToken]);
  //
  useEffect(() => {
    const interval = setInterval(async () => {
      tryUpdateToken();
    }, selectExpiredTime * 1000 - TIME_OUTS.SESSION_REFRESH_TIME * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [selectExpiredTime]);
};
//
export default useUpdateToken;

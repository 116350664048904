/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
/**
 * Initial states of visual function are defined here
 */
export const initialState = {
  //
  loading: false,
  visualFilters: [],
  visualFilterById: [],
};
/**
 * All actions related to visual feature are defined here
 */
export const visualFilterSlice = createSlice({
  name: 'feature/visual-filter',
  initialState,
  reducers: {
    getVisualFilters(state) {
      state.loading = true;
    },
    getVisualFiltersSucceeded(state, action) {
      state.loading = false;
      state.visualFilters = action?.payload.results;
    },
    getVisualFiltersFailed(state) {
      state.loading = false;
    },
    createVisualFilter(state) {
      state.loading = true;
    },
    createVisualFilterSucceeded(state, action) {
      state.loading = false;
      state.visualFilters = [...state.visualFilters, action?.payload];
    },
    createVisualFilterFailed(state) {
      state.loading = false;
    },
    updateVisualFilter(state) {
      state.loading = true;
    },
    updateVisualFilterSucceeded(state, action) {
      state.loading = false;
      state.visualFilters = state?.visualFilters?.map((object) =>
        object?.id === action?.payload?.id ? { ...object, ...action.payload } : object
      );
    },
    updateVisualFilterFailed(state) {
      state.loading = false;
    },
    deleteVisualFilter(state) {
      state.loading = true;
    },
    deleteVisualFilterSucceeded(state, action) {
      state.loading = false;
      state.visualFilters = state?.visualFilters?.filter(
        (object) => object?.id !== action?.payload?.data?.id
      );
    },
    deleteVisualFilterFailed(state) {
      state.loading = false;
    },
    getVisualFilterById(state) {
      state.loading = true;
    },
    getVisualFilterByIdSucceeded(state, action) {
      state.loading = false;
      state.visualFilterById = action?.payload;
    },
    getVisualFilterByIdFailed(state) {
      state.loading = false;
    },
  },
});
//
export const { actions: visualFilterActions } = visualFilterSlice;

/**
 * Define all the .env file related keys
 */
const ENVIRONMENT = {
  BACKEND_API: process.env?.REACT_APP_BACKEND_API,
  APP_NAME: process.env?.REACT_APP_APP_NAME,
  PERSIST_ENCRYPTED_SECRET_KEY: process.env.REACT_APP_PERSIST_ENCRYPTED_SECRET_KEY,
  PERSIST_KEY: process.env.REACT_APP_PERSIST_KEY,
  PERSIST_VERSION: process.env.REACT_APP_PERSIST_VERSION,
  USER_INVITATION_EXPIRATION_MINUTES: process.env.REACT_APP_USER_INVITATION_EXPIRATION_MINUTES,
  KC_DOMAIN: process.env?.REACT_APP_KC_DOMAIN,
  APP_URL: process.env?.REACT_APP_URL,
  KC_REALM: process.env?.REACT_APP_KC_REALM,
  KC_CLIENT_ID: process.env?.REACT_APP_CLIENT_ID,
  KC_PKCE_CHALLENGE_METHOD: process.env?.REACT_APP_KC_PKCE_CHALLENGE_METHOD,
  KC_PKCE_VERIFIER_LENGTH: parseInt(process.env?.REACT_APP_KC_PKCE_VERIFIER_LENGTH, 10),
};
//
export default ENVIRONMENT;

const PAGINATION = {
  VISUALS_PER_PAGE_COUNT: 500,
  VISUAL_FILTERS_PER_PAGE_COUNT: 200,
  MAJOR_EVENT_CATEGORIES_PER_PAGE: 200,
  MINOR_EVENT_CATEGORIES_PER_PAGE: 200,
  HOTELS_PER_PAGE_COUNT: 200,
  USERS_PER_PAGE_COUNT: 200,
  USER_INVITES_PER_PAGE_COUNT: 200,
  AUDIT_LOGS_PER_PAGE_COUNT: 200,
  ALL_USERS_COUNT: 2000,
};
//
export default PAGINATION;

import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

/**
 * Getting entire users state into the component
 */
const selectDomain = (state) => state['feature/users'] || initialState;
/**
 * Getting the loader state into the component
 */
export const selectLoader = createSelector([selectDomain], (usersState) => usersState.loading);
/**
 * Getting the users list state into the component
 */
export const selectUsers = createSelector([selectDomain], (usersState) => usersState?.usersList);
/**
 * Getting the roles list state into the component
 */
export const selectRoleList = createSelector([selectDomain], (usersState) => usersState.roleList);
/**
 * Getting the filtered user by user id state into the component
 */
const getFirstParam = (_, param) => param;
export const selectUserById = createSelector(
  [selectUsers, getFirstParam],
  (selectedUsers, userId) => selectedUsers?.results?.filter((user) => user.id === userId)[0]
);
/**
 * Getting the user hotels list state into the component
 */
export const selectUserHotelsList = createSelector(
  [selectDomain],
  (usersState) => usersState.hotelsList?.results
);
/**
 * Getting the updated user state into the component
 */
export const selectUpdatedUser = createSelector(
  [selectDomain],
  (usersState) => usersState.updatedUser
);
/**
 * Getting the  hotels selection list for dropdown  state into the component
 */
export const selectHotelsSelectionList = createSelector([selectDomain], (usersState) =>
  usersState.hotelSelectionList?.results?.map((data) => ({
    label: data?.name,
    id: data?.id,
    pmsDate: data?.pmsStartDate,
  }))
);
/**
 * Getting the logged user hotels list state into the component
 */
export const selectLoggedUserHotelsList = createSelector([selectDomain], (usersState) =>
  usersState.loggedUserHotelsList?.results?.map((data) => ({
    label: data?.hotel?.name,
    id: data?.hotel?.id,
    pmsDate: data?.hotel?.pmsStartDate,
  }))
);

import { useEffect, useState } from 'react';
import { withTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoader, selectUsers } from 'modules/users/selectors';
import { selectOrganizationId, selectAuthUser } from 'modules/common/auth/selectors';
import { usersActions } from 'modules/users/slice';
import PAGINATION from 'modules/common/constants/pagination-limits';
import ORDER_BY from 'modules/common/constants/order-by';
import { Loader } from 'modules/common/components';
import { isEmpty } from 'modules/common/helpers/object';
import { CardContent, Grid, Card, Typography } from '@mui/material';
import UserDataDrawerView from '../user-data-drawer';
import UsersTableView from '../users-table';
import AddUserFormDialog from '../add-user';
import { Button } from './style';
import InviteUserFormDialog from '../invite-user/invite-modal';

const UsersView = ({ theme }) => {
  const dispatch = useDispatch();
  //
  const loading = useSelector(selectLoader);
  const organizationId = useSelector(selectOrganizationId);
  const users = useSelector(selectUsers);
  const currentUser = useSelector(selectAuthUser);
  const userId = currentUser?.id;
  //
  const [selectedUserId, setSelectedUserId] = useState({});
  const [open, setOpen] = useState(false);
  const [openInvite, setOpenInvite] = useState(false);
  const [show, isShow] = useState(false);
  //
  useEffect(() => {
    dispatch(
      usersActions.usersList({
        organizationId,
        query: `limit=${PAGINATION.USERS_PER_PAGE_COUNT}`,
      })
    );
    const query = `limit=${PAGINATION.HOTELS_PER_PAGE_COUNT}&sortBy=name:${ORDER_BY.ASCENDING}&forceFetch=true`;
    dispatch(usersActions.getSelectionHotelsList({ organizationId, query }));
    dispatch(
      usersActions.getLoggedUserHotelsList({
        organizationId,
        userId,
        query: `limit=${PAGINATION.HOTELS_PER_PAGE_COUNT}`,
      })
    );
  }, []);
  //
  return (
    <Loader loading={loading}>
      <Card>
        <CardContent sx={{ backgroundColor: theme.palette.background.default }}>
          <Grid container direction="row" justifyContent="space-between" pb={4}>
            <Typography variant="h2">Configure Users</Typography>
            <Grid item>
              <Button
                sx={{ marginRight: 2 }}
                variant="contained"
                color="primary"
                onClick={() => setOpenInvite(true)}
              >
                Invite User
              </Button>
              <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                Add User
              </Button>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2} justifyContent="space-between">
            <Grid item sx={{ flexGrow: 1, flexBasis: 1 }}>
              {!isEmpty(users) && (
                <UsersTableView isShow={isShow} setSelectedUserId={setSelectedUserId} />
              )}
            </Grid>
            <UserDataDrawerView show={show} isShow={isShow} userId={selectedUserId} />
          </Grid>
        </CardContent>
      </Card>
      <AddUserFormDialog open={open} onClose={() => setOpen(false)} />
      <InviteUserFormDialog open={openInvite} onClose={() => setOpenInvite(false)} />
    </Loader>
  );
};
//
export default withTheme(UsersView);

/**
 * Page routes are defined here
 */
const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  LOGIN_REDIRECT: '/login-callback',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  VERIFY_CALLBACK: '/verify-callback',
  DASHBOARD: '/dashboard',
  VISUAL_FILTER: '/visual-filter',
  EVENT_CONFIGURATION: '/event-configuration',
  USER_INVITATION: '/user-invitation',
  AUDIT_LOGS: '/audit-logs',
  USERS: '/users',
  NOT_FOUND: '*',
};
//
export default ROUTES;

import { AuthWrapper, PrivateRoute } from 'modules/common/auth/components';
import { ADMIN_ROLE, SUPER_ADMIN_ROLE } from 'modules/common/constants/roles';
import ROUTES from 'modules/common/constants/route';
import AuthLayout from 'modules/common/layouts/auth';
import DashboardLayout from 'modules/common/layouts/dashboard';
import Page404 from 'modules/error-pages/404';
import { ForgotPasswordView } from 'modules/forgot-password/components';
import { ResetPasswordView } from 'modules/reset-password/components';
import { SignInView } from 'modules/sign-in/components';
import { UsersView } from 'modules/users/components';
import VerifyCallback from 'modules/verify-callback/components';
import { VisualFilterView } from 'modules/visual-filter/components';
import { VisualView } from 'modules/visual/components';
import { EventCategoriesConfigureView } from 'modules/event-configuration/components';
import { UserInvitationView } from 'modules/user-invitation/components';
import { Navigate } from 'react-router-dom';
import SignInLayout from 'modules/common/layouts/sign-in';
import SignInAuthWindow from 'modules/sign-in/components/auth-window';
import AuditLogsView from '../modules/audit-logs/components/audit-logs-view';
/**
 * Define the routing structure using array. Here include the nested routeing as well.
 * And also define the auth wrapper and private routers for helping to role based routes
 */
const routes = [
  {
    path: ROUTES.ROOT,
    element: <Navigate to={ROUTES.LOGIN} />,
  },
  {
    path: ROUTES.ROOT,
    element: <SignInLayout />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: <SignInView />,
      },
      {
        path: ROUTES.LOGIN_REDIRECT,
        element: <SignInAuthWindow />,
      },
    ],
  },
  {
    path: ROUTES.ROOT,
    element: <AuthLayout />,
    children: [
      {
        path: ROUTES.FORGOT_PASSWORD,
        element: <ForgotPasswordView />,
      },
      {
        path: ROUTES.RESET_PASSWORD,
        element: <ResetPasswordView />,
      },
    ],
  },
  {
    path: ROUTES.DASHBOARD,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: <PrivateRoute component={VisualView} roles={[SUPER_ADMIN_ROLE, ADMIN_ROLE]} />,
      },
    ],
  },
  {
    path: ROUTES.VISUAL_FILTER,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivateRoute component={VisualFilterView} roles={[SUPER_ADMIN_ROLE, ADMIN_ROLE]} />
        ),
      },
    ],
  },
  {
    path: ROUTES.USERS,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: <PrivateRoute component={UsersView} roles={[SUPER_ADMIN_ROLE, ADMIN_ROLE]} />,
      },
    ],
  },
  {
    path: ROUTES.EVENT_CONFIGURATION,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivateRoute
            component={EventCategoriesConfigureView}
            roles={[SUPER_ADMIN_ROLE, ADMIN_ROLE]}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.USER_INVITATION,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: (
          <PrivateRoute component={UserInvitationView} roles={[SUPER_ADMIN_ROLE, ADMIN_ROLE]} />
        ),
      },
    ],
  },
  {
    path: ROUTES.AUDIT_LOGS,
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      {
        path: '',
        element: <PrivateRoute component={AuditLogsView} roles={[SUPER_ADMIN_ROLE]} />,
      },
    ],
  },
  {
    path: ROUTES.NOT_FOUND,
    element: <Page404 />,
  },
  {
    path: ROUTES.VERIFY_CALLBACK,
    element: <VerifyCallback />,
  },
];
//
export default routes;

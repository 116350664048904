const API = {
  POST_FETCH_USER: {
    path: '/v1/users/:userId',
    method: 'POST',
  },
  POST_SIGN_OUT: {
    path: '/auth/logout',
    method: 'POST',
  },
  POST_REFRESH_TOKENS: {
    path: '/auth/refresh-tokens',
    method: 'POST',
  },
};
export default API;

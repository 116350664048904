import ROUTES from 'modules/common/constants/route';
import { isAllowForRoles } from 'modules/common/helpers/token';
import { SUPER_ADMIN_ROLE } from 'modules/common/constants/roles';
import {
  GroupAddRounded,
  BarChart,
  FilterAlt,
  ManageAccounts,
  EventNote,
  LineWeight,
} from '@mui/icons-material';
/**
 * Sidebar nav items are defined here
 */
const getDashboardItems = (accessToken) => {
  let pagesSection = [
    {
      href: ROUTES.DASHBOARD,
      icon: BarChart,
      title: 'Visuals',
    },
    {
      href: ROUTES.VISUAL_FILTER,
      icon: FilterAlt,
      title: 'Visual Filters',
    },
    {
      href: ROUTES.USERS,
      icon: ManageAccounts,
      title: 'Users',
    },
    {
      href: ROUTES.EVENT_CONFIGURATION,
      icon: EventNote,
      title: 'Event Configuration',
    },
    {
      href: ROUTES.USER_INVITATION,
      icon: GroupAddRounded,
      title: 'User Invitations',
    },
  ];

  if (isAllowForRoles(accessToken, [SUPER_ADMIN_ROLE])) {
    pagesSection = [
      ...pagesSection,
      {
        href: ROUTES.AUDIT_LOGS,
        icon: LineWeight,
        title: 'Audit Logs',
      },
    ];
  }
  //
  return [
    {
      title: 'Dashboard',
      pages: pagesSection,
    },
  ];
};
//
export default getDashboardItems;

/* eslint-disable import/no-cycle  */
import { all, fork } from 'redux-saga/effects';
import { signInSaga } from 'modules/sign-in/saga';
import { authSaga } from 'modules/common/auth/saga';
import { forgotPasswordSaga } from 'modules/forgot-password/saga';
import { resetPasswordSaga } from 'modules/reset-password/saga';
import { verifyTokenSaga } from 'modules/verify-callback/saga';
import { visualSaga } from 'modules/visual/saga';
import { visualFilterSaga } from 'modules/visual-filter/saga';
import { eventCategoryConfigurationSaga } from 'modules/event-configuration/saga';
import { userSaga } from 'modules/users/saga';
import { userInvitesSaga } from 'modules/user-invitation/saga';
import { auditLogsSaga } from 'modules/audit-logs/saga';
/**
 * Register all the saga functions into the root saga
 */
export default function* rootSaga() {
  yield all([
    fork(auditLogsSaga),
    fork(authSaga),
    fork(signInSaga),
    fork(forgotPasswordSaga),
    fork(resetPasswordSaga),
    fork(verifyTokenSaga),
    fork(visualSaga),
    fork(visualFilterSaga),
    fork(eventCategoryConfigurationSaga),
    fork(userSaga),
    fork(userInvitesSaga),
  ]);
}

/* eslint-disable camelcase */
import { useCallback } from 'react';
import store from 'store/index';
import jwt_decode from 'jwt-decode';
import { getRefreshToken } from 'modules/common/helpers/token';
import API from 'modules/sign-in/constants';
import axiosConfig from 'modules/common/utils/axios';

const useIsAuthenticated = () => {
  const token = getRefreshToken();
  const isAuthenticated = useCallback(() => {
    if (token) {
      const { exp } = jwt_decode(token);
      const currentTime = Date.now() / 1000;
      const isTokenRevoked = store?.getState()['feature/base-auth'].isTokenRevoked;
      if (isTokenRevoked) {
        return false;
      }
      return exp >= currentTime;
    }
    return false;
  }, [token]);
  //
  const isActiveToken = useCallback(async () => {
    if (token) {
      try {
        const { data } = await axiosConfig.post(API.POST_TOKEN_INFO.path, { token });
        return data?.active;
      } catch (e) {
        return false;
      }
    }
    return false;
  }, [token]);
  //
  return { isAuthenticated, isActiveToken };
};
//
export default useIsAuthenticated;
